import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { Product } from "../shared/product";
import { LoginService } from "../services/login.service";
import { ProductService } from "../services/product.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { AddAllowableProductsComponent } from "../add-allowable-products/add-allowable-products.component";

@Component({
  selector: "app-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"]
})
export class ProductsComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  editing = {};
  isEditable = {};
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  products: Product[];
  selectedProduct: Product;
  confirmMessage: MatDialogRef<ConfirmationDialogComponent>;
  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public productService: ProductService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      // Do Nothing
      // return true;
    } else {
      this.logOut();
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
    if (this.token) {
      this.fetch();
    }
  }

  fetch() {
    this.productService.getProducts(this.token).subscribe(products => {
      this.products = products;
      // console.log(this.customers);
      this.products.sort(function(a, b) {
        return a.customer._id > b.customer._id
          ? 1
          : b.customer._id > a.customer._id
          ? -1
          : 0;
      });
      this.rows = this.products;
    });
  }

  logOut() {
    this.loginService.logout();
  }

  openAddProduct() {
    this.dialog.open(AddAllowableProductsComponent, {
      width: "400px",
      height: "750px"
    });
  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + "-" + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }
  updateProduct(rowIndex) {
    this.confirmMessage = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmMessage.componentInstance.confirmMessage =
      "Are you sure you want to update this Product?";
    this.confirmMessage.afterClosed().subscribe(result => {
      if (result) {
        this.selectedProduct = this.rows[rowIndex];
        this.productService
          .putProduct(this.selectedProduct, this.token)
          .subscribe(res => {
            if (res.success) {
              this.isEditable[rowIndex] = !this.isEditable[rowIndex];
              console.log("Product Updated");
              this.ngOnInit();
            }
          });
      }
    });
  }
}
