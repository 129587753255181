import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { LoginService } from "../services/login.service";
import { UserService } from "../services/user.service";
import { User } from "../shared/user";
import { MatDialog } from "@angular/material";
import { AddUserComponent } from "../add-user/add-user.component";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  users: User[];

  columns = [
    { prop: "username" },
    { prop: "location" },
    { prop: "active" },
    { prop: "admin" }
  ];

  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public userService: UserService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
    if (this.token) {
      this.fetch(data => {
        // this.rows = data;
        setTimeout(() => {
          this.loadingIndicator = false;
        }, 1500);
      });
    }
  }

  fetch(data) {
    this.userService.getUsers(this.token).subscribe(users => {
      this.users = users;
      // console.log(this.users);
      this.users.sort(function(a, b) {
        return a.username > b.username ? 1 : b.username > a.username ? -1 : 0;
      });
      this.rows = this.users;
    });
  }

  logOut() {
    console.log("LogOUt called");
    this.loginService.logout();
  }

  openAddUser() {
    this.dialog.open(AddUserComponent, { width: "400px", height: "500px" });
  }
}
