import { Routes } from '@angular/router';
import { LoginComponent } from '../login/login.component';
import { ToteDetailsComponent } from '../tote-details/tote-details.component';
import { CustomerComponent } from '../customer/customer.component';
import { MaterialComponent } from '../material/material.component';
import { UserComponent } from '../user/user.component';
import { ReturnTimeComponent } from '../return-time/return-time.component';
import { ProductsComponent } from '../products/products.component';
import { HistoryComponent } from '../history/history.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'tote-details', component: ToteDetailsComponent },
  { path: 'customer', component: CustomerComponent },
  { path: 'material', component: MaterialComponent },
  { path: 'return-time', component: ReturnTimeComponent },
  { path: 'product', component: ProductsComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'user', component: UserComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' }
];
