import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup, Form } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  userForm: FormGroup;
  token: String;
  isSubmit: Boolean = false;

  constructor(public dialogRef: MatDialogRef<AddUserComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public userService: UserService) {

    this.userForm = this.formBuilder.group({
      username: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(15)])],
      location: ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z ]*')])]
    }, this.pwdMatchValidator);
  }

  pwdMatchValidator(frm: FormGroup) {
    return frm.get('password').value === frm.get('confirmPassword').value
      ? null : { 'mismatch': true };
  }

  // get password() { return this.userForm.get('password'); }
  // get confirm_password() { return this.userForm.get('confirm_password'); }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
  }

  onSubmit(): Boolean {
    this.isSubmit = true;
    if (this.userForm.invalid) {
      return false;
    } else {
      if (this.userForm.get('password').value !== this.userForm.get('confirmPassword').value) {
        console.log('passwor dmismatch');
        return false;
      } else {
        this.userService.signUpUser(this.userForm.get('username').value,
          this.userForm.get('password').value, this.userForm.get('location').value, this.token)
          .subscribe(res => {
            if (res.success) {
              console.log('Registraion Successful');
              this.userForm.reset({
                username: '',
                password: '',
                confirmPassword: '',
                location: ''
              });
              this.dialogRef.close();
            }
          });
        return true;
      }
    }
  }

}
