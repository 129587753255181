import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { Customer } from "../shared/customer";
import { LoginService } from "../services/login.service";
import { CustomerService } from "../services/customer.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { AddCustomerComponent } from "../add-customer/add-customer.component";

@Component({
  selector: "app-customer",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"]
})
export class CustomerComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  customers: Customer[];
  editing = {};
  isEditable = {};
  selectedCustomer: Customer;

  confirmMessage: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public customerService: CustomerService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
    if (this.token) {
      this.fetch();
    }
  }

  fetch() {
    this.customerService.getCustomers(this.token).subscribe(customers => {
      this.customers = customers;
      // console.log(this.customers);
      this.customers.sort(function(a, b) {
        return a.customerId > b.customerId
          ? 1
          : b.customerId > a.customerId
          ? -1
          : 0;
      });
      this.rows = this.customers;
    });
  }

  logOut() {
    // console.log('LogOUt called');
    this.loginService.logout();
  }

  openAddCustomer() {
    this.dialog.open(AddCustomerComponent, { width: "400px", height: "650px" });
  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + "-" + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  updateCustomer(rowIndex) {
    this.confirmMessage = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmMessage.componentInstance.confirmMessage =
      "Are you sure you want to update this Customer?";
    this.confirmMessage.afterClosed().subscribe(result => {
      if (result) {
        this.selectedCustomer = this.rows[rowIndex];
        this.customerService
          .putCustomer(this.selectedCustomer, this.token)
          .subscribe(res => {
            if (res.success) {
              this.isEditable[rowIndex] = !this.isEditable[rowIndex];
              console.log("Customer Updated");
              this.ngOnInit();
              // bootbox.showAlert('Customer Updated!');
            }
          });
      }
    });
  }
}
