import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { Return } from "../shared/returnTime";
import { LoginService } from "../services/login.service";
import { ReturnTimeService } from "../services/return-time.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { AddReturnTimeComponent } from "../add-return-time/add-return-time.component";

@Component({
  selector: "app-return-time",
  templateUrl: "./return-time.component.html",
  styleUrls: ["./return-time.component.scss"]
})
export class ReturnTimeComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  editing = {};
  isEditable = {};
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  returnTimes: Return[];
  selectedReturnTime: Return;

  confirmMessage: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public returnTimeService: ReturnTimeService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
    if (this.token) {
      this.fetch(data => {
        // this.rows = data;
        setTimeout(() => {
          this.loadingIndicator = false;
        }, 1500);
      });
    }
  }

  fetch(data) {
    this.returnTimeService.getReturnTimes(this.token).subscribe(returnTimes => {
      this.returnTimes = returnTimes;
      // console.log(this.customers);
      this.returnTimes.sort(function(a, b) {
        return a.country > b.country ? 1 : b.country > a.country ? -1 : 0;
      });
      this.rows = this.returnTimes;
    });
  }

  logOut() {
    this.loginService.logout();
  }

  openReturnTime() {
    this.dialog.open(AddReturnTimeComponent, {
      width: "400px",
      height: "550px"
    });
  }

  updateValue(event, cell, rowIndex) {
    // console.log('inline editing rowIndex', rowIndex);
    this.editing[rowIndex + "-" + cell] = false;
    this.rows[rowIndex][cell] = event.target.value;
    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  updateReturnTime(rowIndex) {
    this.confirmMessage = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false
    });
    this.confirmMessage.componentInstance.confirmMessage =
      "Are you sure you want to update this Return Time?";
    this.confirmMessage.afterClosed().subscribe(result => {
      if (result) {
        this.selectedReturnTime = this.rows[rowIndex];
        this.returnTimeService
          .putReturnTime(this.selectedReturnTime, this.token)
          .subscribe(res => {
            if (res.success) {
              this.isEditable[rowIndex] = !this.isEditable[rowIndex];
              console.log("return time Updated");
              this.ngOnInit();
            }
          });
      }
    });
  }
}
