import { Material } from "./../shared/material";
import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { History } from "../shared/history";
import { LoginService } from "../services/login.service";
import { HistoryService } from "../services/history.service";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  histories: History[];
  temp = [];

  columns = [
    { prop: "serialNumber" },
    { prop: "transaction" },
    { prop: "changedOn" },
    { prop: "medium" },
    { prop: "assignedToDesc" },
    { prop: "estReturnDate" },
    {
      prop: "material.description",
      name: "Tote Type",
    },
    { prop: "currentLocation" },
    { prop: "ownerDesc" },
    { prop: "currentStatus" },
    { prop: "manufactureDate" },
    { prop: "lastInspectionDate" },
    { prop: "nextInspectionDate" },
    { prop: "expiryDate" },
    { prop: "product" },
    { prop: "shipDate" },
    { prop: "currentBatch" },
    { prop: "changedBy" },
  ];
  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public historyService: HistoryService
  ) {}

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
    if (this.token) {
      this.fetch((data) => {
        // this.rows = data;
        setTimeout(() => {
          this.loadingIndicator = false;
        }, 1500);
      });
    }
  }

  fetch(data) {
    this.historyService.getHistory(this.token).subscribe((histories) => {
      this.histories = histories;

      this.histories.sort(function (a, b) {
        return b.changedOn > a.changedOn
          ? 1
          : a.changedOn > b.changedOn
          ? -1
          : 0;
      });

      for (let i = 0; i < this.histories.length; i++) {
        if (this.histories[i].lastInspectionDate) {
          this.histories[i].lastInspectionDate = this.formatDate(
            this.histories[i].lastInspectionDate
          );
        }
        if (this.histories[i].manufactureDate) {
          this.histories[i].manufactureDate = this.formatDate(
            this.histories[i].manufactureDate
          );
        }
        if (this.histories[i].expiryDate) {
          this.histories[i].expiryDate = this.formatDate(
            this.histories[i].expiryDate
          );
        }
        if (this.histories[i].nextInspectionDate) {
          this.histories[i].nextInspectionDate = this.formatDate(
            this.histories[i].nextInspectionDate
          );
        }
        if (this.histories[i].shipDate) {
          this.histories[i].shipDate = this.formatDate(
            this.histories[i].shipDate
          );
        }
        if (this.histories[i].estReturnDate) {
          this.histories[i].estReturnDate = this.formatDate(
            this.histories[i].estReturnDate
          );
        }
        if (this.histories[i].changedOn) {
          this.histories[i].changedOn = this.formatDateTime(
            this.histories[i].changedOn
          );
        }
        this.histories[i].ownerDesc =
          this.histories[i].owner.description +
          "-" +
          this.histories[i].owner.customerLocation;
        this.histories[i].assignedToDesc =
          this.histories[i].assignedTo.description +
          "-" +
          this.histories[i].assignedTo.customerLocation;
      }

      this.rows = this.histories;
      // cache our list
      this.temp = this.histories;
    });
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serialNumber.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }

  logOut() {
    this.loginService.logout();
  }

  formatDate(date: string) {
    let newDate = new Date(date);
    newDate = new Date(
      newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000
    );

    const offset = newDate.getTimezoneOffset() / 60;
    const hours = newDate.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleDateString();
  }

  formatDateTime(date: string) {
    let newDate = new Date(date);
    newDate = new Date(
      newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000
    );

    const offset = newDate.getTimezoneOffset() / 60;
    const hours = newDate.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString();
  }

  saveToDisk() {
    const data = this.rows;
    data.map(function (item) {
      delete item.material;
      delete item.owner;
      delete item.assignedTo;
      delete item._id;
      delete item.__v;

      return item;
    });

    this.exportAsExcelFile(this.rows, "history");
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }
}
