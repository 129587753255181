import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

    getUsers(token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.get(baseURL + 'users', options)
        .pipe(map(res => this.processHttpmsgService.extractData(res)))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    signUpUser(username: String, password: String, location: String, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.post(baseURL + 'users/signup', {
        'username': username,
        'password': password,
        'location': location
      },
        options)
        .pipe(map(res => {
          return { 'success': true };
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

}
