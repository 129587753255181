import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';
import { Material } from '../shared/material';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

    getMaterials(token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.get(baseURL + 'materials', options)
        .pipe(map(res => this.processHttpmsgService.extractData(res)))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    getMaterialById(id: string, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.get(baseURL + 'materials/' + id, options)
        .pipe(map(res => this.processHttpmsgService.extractData(res)))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    postMaterial(material: Material, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.post(baseURL + 'materials',
        {
          'material': material.material,
          'organization': material.organization,
          'description': material.description,
          'assetType': material.assetType,
          'shelfLife': material.shelfLife,
          'inspectionInterval': material.inspectionInterval,
          'warningDays': material.warningDays
        },
        options)
        .pipe(map (res => {
          return { 'success': true, 'materialId': material.material };
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    putMaterial(material: Material, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.put(baseURL + 'materials',
        {
          'material': material.material,
          'shelfLife': material.shelfLife,
          'inspectionInterval': material.inspectionInterval,
          'warningDays': material.warningDays
        },
        options)
        .pipe(map (res => {
          return { 'success': true, 'materialId': material.material };
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

}
