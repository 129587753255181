import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';
import { Product } from '../shared/product';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

  getProducts(token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.get(baseURL + 'products', options)
      .pipe(map(res => this.processHttpmsgService.extractData(res)))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  postProduct(product: Product, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.post(baseURL + 'products', {
      'customer': product.customer,
      'product': product.product,
      'weight': product.weight,
      'toteType': product.toteType,
      'dipTube': product.dipTube,
      'cap': product.cap,
      'ventPlug': product.ventPlug
    },
      options)
      .pipe(map(res => {
        return { 'success': true };
      }))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  putProduct(product: Product, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.put(baseURL + 'products', {
      'customerId': product.customer.customerId,
      'catalogId': product.customer.catalogId,
      'product': product.product,
      'weight': product.weight,
      'dipTube': product.dipTube,
      'cap': product.cap,
      'ventPlug': product.ventPlug
    },
      options)
      .pipe(map(res => {
        return { 'success': true };
      }))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

}
