import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';
import { Return } from '../shared/returnTime';

@Injectable({
  providedIn: 'root'
})
export class ReturnTimeService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

    getReturnTimes(token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.get(baseURL + 'return-time', options)
        .pipe(map(res => this.processHttpmsgService.extractData(res)))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    postReturnTime(returnTime: Return, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.post(baseURL + 'return-time',
        {
          'state': returnTime.state,
          'country': returnTime.country,
          'description': returnTime.description,
          'returnDays': returnTime.returnDays,
          'eligibilityDays': returnTime.eligibilityDays
        },
        options)
        .pipe(map (res => {
          return { 'success': true};
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    putReturnTime(returnTime: Return, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.put(baseURL + 'return-time',
        {
          'country': returnTime.country,
          'state': returnTime.state,
          'returnDays': returnTime.returnDays,
          'eligibilityDays': returnTime.eligibilityDays
        },
        options)
        .pipe(map (res => {
          return { 'success': true};
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }
}
