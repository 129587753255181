import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { baseURL } from "../shared/baseurl";
import { map, catchError } from "rxjs/operators";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { Tote } from "../shared/tote";

@Injectable({
  providedIn: "root",
})
export class ToteService {
  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService
  ) {}

  getTotes(token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http
      .get(baseURL + "assets", options)
      .pipe(map((res) => this.processHttpmsgService.extractData(res)))
      .pipe(
        catchError((error) => this.processHttpmsgService.handleError(error))
      );
  }

  getToteById(id: string, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http
      .get(baseURL + "assets/" + id, options)
      .pipe(map((res) => this.processHttpmsgService.extractData(res)))
      .pipe(
        catchError((error) => this.processHttpmsgService.handleError(error))
      );
  }

  postTote(tote: Tote, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http
      .post(
        baseURL + "assets/manual-create",
        {
          serialNumber: tote.serialNumber,
          material: tote.material,
          owner: tote.owner,
          assignedTo: tote.assignedTo,
          manufactureDate: tote.manufactureDate,
          lastInspectionDate: tote.lastInspectionDate,
          currentStatus: tote.currentStatus,
          currentLocation: tote.currentLocation,
          product: tote.product,
          shipDate: tote.shipDate,
          currentBatch: tote.currentBatch,
          nextInspectionDate: tote.nextInspectionDate,
          expiryDate: tote.expiryDate,
          estReturnDate: tote.estReturnDate,
        },
        options
      )
      .pipe(
        map((res) => {
          return { success: true };
        })
      )
      .pipe(
        catchError((error) => this.processHttpmsgService.handleError(error))
      );
  }

  updateTote(tote: Tote, token: String, estReturnDate: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http
      .put(
        baseURL + "assets/portal-update",
        {
          _id: tote._id,
          serialNumber: tote.serialNumber,
          material: tote.material._id,
          owner: tote.owner._id,
          assignedTo: tote.assignedTo._id,
          manufactureDate: tote.manufactureDate,
          lastInspectionDate: tote.lastInspectionDate,
          currentStatus: tote.currentStatus,
          currentLocation: tote.currentLocation,
          product: tote.product,
          shipDate: tote.shipDate,
          currentBatch: tote.currentBatch,
          estReturnDate: estReturnDate,
          toteSampled: tote.toteSampled,
          toteRinsed: tote.toteRinsed,
          lastRinsedDate: tote.lastRinsedDate,
        },
        options
      )
      .pipe(
        map((res) => {
          return { success: true };
        })
      )
      .pipe(
        catchError((error) => this.processHttpmsgService.handleError(error))
      );
  }

  deleteTote(id: String, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http
      .delete(baseURL + "assets/delete/" + id, options)
      .subscribe((res) => {
        console.log(res);
      });
  }
}
