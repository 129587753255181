import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private isLoggedIn = false;
  returnUrl: string;
  constructor(public http: HttpClient,
    private route: ActivatedRoute,
    private router: Router) { }

  // Is user authenticated and available to use the application
  public authenticated() {
    return this.isLoggedIn;
  }

  // Authenticate user
  public userAuthentication(bool) {
    this.isLoggedIn = bool;
  }


  // Check token expiration and refresh if needed
  public tokenExpiryValidationCheck(): boolean {
    // console.log("expiry check start");

    let tokenTime;
    let tokenExp;
    let currentUser;
    const currentTime = new Date().getTime();

    if (localStorage.getItem('currentTime')) {
      tokenTime = new Date(localStorage.getItem('currentTime')).getTime();
    }
    if (localStorage.getItem('expiresInMinutes')) {
      tokenExp = localStorage.getItem('expiresInMinutes');
    }
    if (localStorage.getItem('currentUser')) {
      currentUser = localStorage.getItem('currentUser');
    }

    if (localStorage.getItem('token')) {
      const val = localStorage.getItem('token');
      if (val !== null && val !== undefined && val !== '') {

        // const difference = (currentTime - tokenTime) / 60 / 1000 - tokenExp + (tokenExp / 2);

        // if (difference < 0) {
        //   this.userAuthentication(true);
        // }

        // // should be (difference > 0)

        // if (difference > 0) {
        //   this.userAuthentication(false);
        // }
        this.userAuthentication(true);
        return this.authenticated();
      } else {
        this.userAuthentication(false);
        return this.authenticated();
      }
    }
  }

  public logout() {
    localStorage.clear();
    this.isLoggedIn = false;
    this.returnUrl = '/login';
    this.router.navigate([this.returnUrl]);
  }


}

