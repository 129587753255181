import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup, Form } from '@angular/forms';
import { Product } from '../shared/product';
import { LoginService } from '../services/login.service';
import { ProductService } from '../services/product.service';
import { Router } from '@angular/router';
import { Customer } from '../shared/customer';
import { CustomerService } from '../services/customer.service';
import { MaterialService } from '../services/material.service';
import { Material } from '../shared/material';

@Component({
  selector: 'app-add-allowable-products',
  templateUrl: './add-allowable-products.component.html',
  styleUrls: ['./add-allowable-products.component.scss']
})
export class AddAllowableProductsComponent implements OnInit {

  product: Product;
  productForm: FormGroup;
  token: String;
  postError: Boolean = false;
  customers: Customer[];
  materials: Material[];

  constructor(public dialogRef: MatDialogRef<AddAllowableProductsComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public productService: ProductService,
    private router: Router,
    private customerService: CustomerService,
    private materialService: MaterialService) {

      this.productForm = this.formBuilder.group({
        customer: ['', Validators.compose([Validators.required])],
        product: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        weight: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        toteType: ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z ]*')])],
        dipTube: ['', Validators.compose([Validators.pattern('[A-Z0-9]*')])],
        cap: ['', Validators.compose([Validators.pattern('[A-Z0-9]*')])],
        ventPlug: ['', Validators.compose([Validators.pattern('[A-Z0-9]*')])]
      });

    }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      this.customerService.getCustomers(this.token)
      .subscribe((customers) => {
        this.customers = customers;
      });

      this.materialService.getMaterials(this.token)
      .subscribe((materials) => {
        this.materials = materials;
      });
    }
  }

  onSubmit() {
    if (this.productForm.valid) {
      this.product = this.productForm.value;
      console.log(this.product);
      // this.customerId = this.product.
      this.productService.postProduct(this.product, this.token)
      .subscribe(res => {
        if (res.success) {
          this.productForm.reset({
            customer: '',
            catalogId: 0,
            product: 0,
            weight: 0,
            toteType: '',
            dipTube: '',
            cap: '',
            ventPlug: ''
          });
          this.dialogRef.close();
          this.router.navigateByUrl('/products');
        } else {
          // this.postError = true;
        }
      });
    }
  }

}
