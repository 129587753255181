import { Component, OnInit } from "@angular/core";
import { Http } from "@angular/http";
import { CommonService } from "../services/common.service";
import { ProcessHttpmsgService } from "../services/process-httpmsg.service";
import { Tote } from "../shared/tote";
import { LoginService } from "../services/login.service";
import { ToteService } from "../services/tote.service";
import { MatDialog, MatDialogRef } from "@angular/material";
import { AddToteComponent } from "../add-tote/add-tote.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { CustomerService } from "../services/customer.service";
import { Customer } from "../shared/customer";
import { Material } from "../shared/material";
import { MaterialService } from "../services/material.service";
import { Return } from "../shared/returnTime";
import { ReturnTimeService } from "../services/return-time.service";
import { HistoryService } from "../services/history.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-tote-details",
  templateUrl: "./tote-details.component.html",
  styleUrls: ["./tote-details.component.scss"],
})
export class ToteDetailsComponent implements OnInit {
  isLoggedIn: Boolean = false;
  rows = [];
  loadingIndicator: Boolean = true;
  reorderable: Boolean = true;
  token: String;
  location: string;
  user: string;
  totes: Tote[] = [];
  interval;
  editing = {};
  isEditable = {};
  owners: Customer[] = [];
  assignedTos: Customer[] = [];
  materials: Material[] = [];
  returnTimes: Return[] = [];
  selectedTote: Tote;
  temp = [];
  statuses: Status[] = [
    { value: "New", viewValue: "New" },
    { value: "Onsite", viewValue: "Onsite" },
    { value: "Pre-Rinse", viewValue: "Pre-Rinse" },
    { value: "Ready for Packaging", viewValue: "RFP" },
    { value: "Packaged", viewValue: "Packaged" },
    { value: "Shipped", viewValue: "Shipped" },
    { value: "Expired", viewValue: "Expired" },
    { value: "Damaged", viewValue: "Damaged" },
    { value: "ReworkInProcess", viewValue: "Rework" },
    { value: "Disposed", viewValue: "Disposed" },
  ];
  history = {
    serialNumber: "",
    currentStatus: "",
    currentLocation: "",
    transaction: "",
    changedBy: "",
    medium: "",
  };
  confirmMessage: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public http: Http,
    public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService,
    public loginService: LoginService,
    public toteService: ToteService,
    public dialog: MatDialog,
    public customerService: CustomerService,
    public materialService: MaterialService,
    public returnTimeService: ReturnTimeService,
    public historyService: HistoryService
  ) {}

  fetch() {
    let today = new Date();
    this.toteService.getTotes(this.token).subscribe((totes) => {
      this.totes = totes;
      // console.log(this.totes);
      for (let i = 0; i < this.totes.length; i++) {
        if (this.totes[i].lastInspectionDate) {
          this.totes[i].lastInspectionDate = this.formatDate(
            this.totes[i].lastInspectionDate
          );
        }
        if (this.totes[i].manufactureDate) {
          this.totes[i].manufactureDate = this.formatDate(
            this.totes[i].manufactureDate
          );
        }
        if (this.totes[i].expiryDate) {
          this.totes[i].expiryDate = this.formatDate(this.totes[i].expiryDate);
          if (this.totes[i].currentStatus !== "Expired") {
            this.checkExpiration(this.totes[i]);
          }
        }
        if (this.totes[i].nextInspectionDate) {
          this.totes[i].nextInspectionDate = this.formatDate(
            this.totes[i].nextInspectionDate
          );
        }
        if (this.totes[i].lastRinsedDate) {
          this.totes[i].lastRinsedDate = this.formatDate(
            this.totes[i].lastRinsedDate
          );
        }
        if (this.totes[i].shipDate) {
          this.totes[i].shipDate = this.formatDate(this.totes[i].shipDate);
        }
        if (this.totes[i].estReturnDate) {
          this.totes[i].estReturnDate = this.formatDate(
            this.totes[i].estReturnDate
          );
        }
      }
      this.totes.sort(function (a, b) {
        return a.serialNumber > b.serialNumber
          ? 1
          : b.serialNumber > a.serialNumber
          ? -1
          : 0;
      });
      this.rows = this.totes;
      // Cache the list
      this.temp = this.totes;
    });
  }

  formatDate(date: string) {
    let newDate = new Date(date);
    newDate = new Date(
      newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000
    );

    const offset = newDate.getTimezoneOffset() / 60;
    const hours = newDate.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleDateString();
  }

  checkExpiration(currentTote) {
    let expiration = new Date(currentTote.expiryDate);
    if (expiration < new Date()) {
      currentTote.currentStatus = "Expired";
      this.toteService
        .updateTote(currentTote, this.token, currentTote.estReturnDate)
        .subscribe((res) => {
          if (res.success) {
            this.history.serialNumber = currentTote.serialNumber;
            this.history.currentStatus = currentTote.currentStatus;
            this.history.currentLocation = this.location;
            this.history.transaction = "Auto Expiration";
            this.history.medium = "Portal";
            this.history.changedBy = "System";
            // Post History
            this.historyService
              .postHistory(this.history, this.token)
              .subscribe((result) => {
                if (result.success) {
                  console.log("History Added");
                }
              });

            this.ngOnInit();
          }
        });
    }
  }

  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (this.isLoggedIn === true) {
      this.token = localStorage.getItem("token");
      this.location = localStorage.getItem("location");
      this.user = localStorage.getItem("currentUser");
      this.customerService.getCustomers(this.token).subscribe((customers) => {
        this.owners = customers.filter((owner) => owner.toteOwner === true);
      });

      this.customerService.getCustomers(this.token).subscribe((assignedTos) => {
        this.assignedTos = assignedTos;
      });

      this.materialService.getMaterials(this.token).subscribe((materials) => {
        this.materials = materials;
      });

      this.returnTimeService
        .getReturnTimes(this.token)
        .subscribe((returnTimes) => {
          this.returnTimes = returnTimes;
        });
    } else {
      this.logOut();
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }

    if (this.token) {
      this.fetch();
      //   this.interval = setInterval(() => {
      //     this.fetch();
      // }, 2000);
    }
  }

  logOut() {
    console.log("LogOUt called");
    this.loginService.logout();
  }

  addTote() {
    this.dialog.open(AddToteComponent, { width: "400px", height: "750px" });
  }

  updateValue(event, cell, rowIndex) {
    this.editing[rowIndex + "-" + cell] = false;
    switch (cell) {
      case "owner._id": {
        this.rows[rowIndex]["owner"]["_id"] = event.target.value;
        break;
      }
      case "assignedTo._id": {
        this.rows[rowIndex]["assignedTo"]["_id"] = event.target.value;
        break;
      }
      case "material._id": {
        this.rows[rowIndex]["material"]["_id"] = event.target.value;
        break;
      }
      default: {
        this.rows[rowIndex][cell] = event.target.value;
        break;
      }
    }

    this.rows = [...this.rows];
    // console.log('UPDATED!', this.rows[rowIndex][cell]);
  }

  getOwnerDescription(id) {
    let returnValue;
    const ownerReturn = this.owners.filter((owner) => owner._id === id);
    if (ownerReturn[0]) {
      returnValue =
        ownerReturn[0].description + "-" + ownerReturn[0].customerLocation;
    } else {
      returnValue = "";
    }
    return returnValue;
  }

  getAssignedToDescription(id) {
    let returnValue;
    const customerReturn = this.assignedTos.filter(
      (assignedTo) => assignedTo._id === id
    );
    if (customerReturn[0]) {
      returnValue =
        customerReturn[0].description +
        "-" +
        customerReturn[0].customerLocation;
    } else {
      returnValue = "";
    }
    return returnValue;
  }

  getMaterialDescription(id) {
    let returnValue;
    const materialReturn = this.materials.filter(
      (material) => material._id === id
    );
    if (materialReturn[0]) {
      returnValue = materialReturn[0].description;
    } else {
      returnValue = "";
    }
    return returnValue;
  }

  updateTote(rowIndex) {
    this.confirmMessage = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
    });
    this.confirmMessage.componentInstance.confirmMessage =
      "Are you sure you want to update Tote " +
      this.rows[rowIndex].serialNumber +
      "?";
    this.confirmMessage.afterClosed().subscribe((result) => {
      if (result) {
        this.selectedTote = this.rows[rowIndex];
        const updateTote = this.selectedTote;
        // Find the Estimated Return Date
        const returnTimeArray = this.returnTimes.filter(
          (returnTimeFetched) =>
            returnTimeFetched.country === this.selectedTote.assignedTo.country
        );
        const returnTime = returnTimeArray[0];
        const estReturnDate = this.calculateReturnTime(returnTime.returnDays);
        // Converting Dates back to ISO String
        if (this.selectedTote.manufactureDate) {
          updateTote.manufactureDate = new Date(
            this.selectedTote.manufactureDate
          ).toISOString();
        }
        if (this.selectedTote.shipDate) {
          updateTote.shipDate = new Date(
            this.selectedTote.shipDate
          ).toISOString();
        }
        if (this.selectedTote.lastInspectionDate) {
          updateTote.lastInspectionDate = new Date(
            this.selectedTote.lastInspectionDate
          ).toISOString();
        }
        if (this.selectedTote.lastRinsedDate) {
          updateTote.lastRinsedDate = new Date(
            this.selectedTote.lastRinsedDate
          ).toISOString();
        }
        this.toteService
          .updateTote(updateTote, this.token, estReturnDate)
          .subscribe((res) => {
            if (res.success) {
              this.isEditable[rowIndex] = !this.isEditable[rowIndex];

              // console.log('Tote Updated');
              // location.reload();
              this.history.serialNumber = this.selectedTote.serialNumber;
              this.history.currentStatus = this.selectedTote.currentStatus;
              this.history.currentLocation = this.location;
              this.history.transaction = "Portal Update";
              this.history.medium = "Portal";
              this.history.changedBy = this.user;
              // Post History
              this.historyService
                .postHistory(this.history, this.token)
                .subscribe((result) => {
                  if (result.success) {
                    console.log("History Added");
                  }
                });

              this.ngOnInit();
            }
          });
      }
    });
  }

  calculateReturnTime(returnTime: number) {
    const today = new Date();
    today.setDate(today.getDate() + returnTime);
    const estDate = today.toISOString();
    return estDate;
  }

  deleteTote(rowIndex) {
    this.confirmMessage = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
    });
    this.confirmMessage.componentInstance.confirmMessage =
      "Are you sure you want to delete Tote " +
      this.rows[rowIndex].serialNumber +
      "?";

    this.confirmMessage.afterClosed().subscribe((result) => {
      if (result) {
        const deleteObj = this.rows[rowIndex];
        this.history.serialNumber = deleteObj.serialNumber;
        this.history.currentStatus = deleteObj.currentStatus;
        this.history.currentLocation = this.location;
        this.history.transaction = "Portal Delete";
        this.history.medium = "Portal";
        this.history.changedBy = this.user;
        // Post History
        this.historyService
          .postHistory(this.history, this.token)
          .subscribe((res) => {
            if (res.success) {
              console.log("History Added");
            }
            this.toteService.deleteTote(deleteObj._id, this.token);
            location.reload();
          });
      }
      this.confirmMessage = null;
    });
  }

  saveToDisk() {
    const data = this.totes;
    data.map(function (item) {
      delete item.material;
      delete item.owner;
      delete item.assignedTo;
      delete item._id;

      return item;
    });
    this.exportAsExcelFile(data, "tote-details");
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.xlsx`;
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ["data"],
    };
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.temp.filter(function (d) {
      return d.serialNumber.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.rows = temp;
    // Whenever the filter changes, always go back to the first page
    // this.table.offset = 0;
  }
}
export interface Status {
  value: string;
  viewValue: string;
}
