import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from '../shared/customer';
import { LoginService } from '../services/login.service';
import { CustomerService } from '../services/customer.service';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit {

  customer: Customer;
  customerForm: FormGroup;
  token: string;


  constructor(public dialogRef: MatDialogRef<AddCustomerComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public customerService: CustomerService) {
      this.customerForm = this.formBuilder.group({
        customerId: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        catalogId: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        description: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z -]*')])],
        customerLocation: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z -]*')])],
        threePL: [false, Validators.compose([Validators.required])],
        state: ['', Validators.compose([Validators.pattern('[a-zA-Z]*')])],
        country: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*')])],
        toteOwner: [false, Validators.compose([Validators.required])],
        org: [false, Validators.compose([Validators.required])]
      });
    }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
  }

  onSubmit() {
    if (this.customerForm.valid) {
      this.customer = this.customerForm.value;
      console.log(this.customer);
      this.customerService.postCustomer(this.customer, this.token)
      .subscribe(res => {
        if (res.success) {
          console.log( 'customer Added', res.customerId);
          this.customerForm.reset({
            customerId: '',
            catalogId: '',
            description: '',
            customerLocation: '',
            threePL: false,
            state: '',
            country: '',
            toteOwner: true,
            org: false
          });
          this.dialogRef.close();
        }
      });
    }
  }

}
