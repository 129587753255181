import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { baseURL } from '../shared/baseurl';
import { HttpClient } from '@angular/common/http';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginError: Boolean = false;
  loginForm: FormGroup;
  user: {
    username: string;
    password: string;
  };
  isLoggedIn: Boolean;
  returnUrl: string;

  alert: {
    id: 1;
    type: 'danger';
    message: 'Invalid Username or Password';
  };

  // tslint:disable-next-line:no-trailing-whitespace
  constructor(private fb: FormBuilder, public http: HttpClient, public loginService: LoginService, 
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.createForm();
   this.isLoggedIn =  this.loginService.tokenExpiryValidationCheck();

   }


  createForm() {
    this.loginForm = this.fb.group({
      username: '',
      password: ''
    });
  }

  onSubmit() {
    this.user = this.loginForm.value;

    this.signin();
    this.loginForm.reset();
  }

  ngOnInit() {
    this.returnUrl = '/tote-details';
  }

  signin() {
    let response: any = {};
    const apiURL = baseURL + 'users/login';
    const promise = new Promise((resolve, reject) => {
      this.http.post(apiURL, { username: this.user.username, password: this.user.password })
        .toPromise()
        .then(
          res => { // Success
            // this.authService.userAuthentication(true);
            response = res;
            if (response && response.token) {
              localStorage.setItem('currentUser', response.user);
              localStorage.setItem('token', response.token);
              localStorage.setItem('currentTime', new Date().getTime().toString());
              localStorage.setItem('location', response.location);
              localStorage.setItem('expiresInMinutes', response.expiresInMinutes);
              // this.navCtrl.setRoot('HomePage');
              // console.log(localStorage);
            }
            resolve();
            this.router.navigate([this.returnUrl]);
          },
          err => {
            response = err;
            // console.log(response);
            this.loginError = true;
          }
        );
    });
    return promise;
  }

  closeAlert() {
    this.loginError = false;
  }
}

