import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup, Form } from '@angular/forms';
import { Return } from '../shared/returnTime';
import { LoginService } from '../services/login.service';
import { ReturnTimeService } from '../services/return-time.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-return-time',
  templateUrl: './add-return-time.component.html',
  styleUrls: ['./add-return-time.component.scss']
})
export class AddReturnTimeComponent implements OnInit {

  returnTime: Return;
  returnTimeForm: FormGroup;
  token: String;

  constructor(public dialogRef: MatDialogRef<AddReturnTimeComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public returnTimeService: ReturnTimeService,
    public router: Router) {
    this.returnTimeForm = this.formBuilder.group({
      country: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z]*')])],
      description: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z- ]*')])],
      state: ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z* ]*')])],
      returnDays: [0, Validators.compose([Validators.pattern('[0-9]*')])],
      eligibilityDays: [0, Validators.compose([Validators.required, Validators.pattern('[0-9]*')])]
    });
  }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
  }

  onSubmit() {
    if (this.returnTimeForm.valid) {
      this.returnTime = this.returnTimeForm.value;
      this.returnTimeService.postReturnTime(this.returnTime, this.token)
        .subscribe(res => {
          if (res.success) {
            this.returnTimeForm.reset({
              country: '',
              description: '',
              state: '',
              returnDays: 0,
              eligibilityDays: 0
            });
            // this.router.navigateByUrl('/return-timet', { skipLocationChange: true }).then(() =>
            //   this.router.navigate(['return-time']));
            this.dialogRef.close();
          }
        });
    }
  }

}
