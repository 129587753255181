import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
// import { MaterialModule } from '@angular/material';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing/app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HashLocationStrategy, LocationStrategy } from "@angular/common";

import "hammerjs";
import { ToteDetailsComponent } from "./tote-details/tote-details.component";
import { LoginComponent } from "./login/login.component";
import { CustomerComponent } from "./customer/customer.component";
import { MaterialComponent } from "./material/material.component";
import { HeaderComponent } from "./header/header.component";
import { UserComponent } from "./user/user.component";
import { AddCustomerComponent } from "./add-customer/add-customer.component";
import { AddMaterialComponent } from "./add-material/add-material.component";
import { ReturnTimeComponent } from "./return-time/return-time.component";
import { ProductsComponent } from "./products/products.component";
import { AddReturnTimeComponent } from "./add-return-time/add-return-time.component";
import { AddAllowableProductsComponent } from "./add-allowable-products/add-allowable-products.component";
import { AddUserComponent } from "./add-user/add-user.component";
import { AddToteComponent } from "./add-tote/add-tote.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { HistoryComponent } from "./history/history.component";

// import { PopupModule } from 'ng2-opd-popup';

@NgModule({
  declarations: [
    AppComponent,
    ToteDetailsComponent,
    LoginComponent,
    CustomerComponent,
    MaterialComponent,
    HeaderComponent,
    UserComponent,
    AddCustomerComponent,
    AddMaterialComponent,
    ReturnTimeComponent,
    ProductsComponent,
    AddReturnTimeComponent,
    AddAllowableProductsComponent,
    AddUserComponent,
    AddToteComponent,
    ConfirmationDialogComponent,
    HistoryComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    FlexLayoutModule,
    NgxDatatableModule,
    MatFormFieldModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,
    AppRoutingModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgbModule.forRoot()
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MatDatepickerModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AddCustomerComponent,
    AddMaterialComponent,
    AddReturnTimeComponent,
    AddAllowableProductsComponent,
    AddUserComponent,
    AddToteComponent,
    ConfirmationDialogComponent
  ]
})
export class AppModule {}
