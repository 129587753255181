import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';
import { Customer } from '../shared/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

  getCustomers(token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.get(baseURL + 'customers', options)
      .pipe(map(res => this.processHttpmsgService.extractData(res)))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  getCustomerById(id: string, token: string) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.get(baseURL + 'customers/' + id, options)
      .pipe(map(res => this.processHttpmsgService.extractData(res)))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  postCustomer(customer: Customer, token: String) {
    const options = this.commonService.createOptionsForHttp(token);

    return this.http.post(baseURL + 'customers',
      {
        'customerId': customer.customerId,
        'catalogId': customer.catalogId,
        'description': customer.description,
        'customerLocation': customer.customerLocation,
        'threePL': customer.threePL,
        'state': customer.state,
        'country': customer.country,
        'toteOwner': customer.toteOwner,
        'org': false
      },
      options)
      .pipe(map(res => {
        return { 'success': true, 'customerId': customer.customerId };
      }))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  putCustomer(customer: Customer, token: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.put(baseURL + 'customers', {
      'customerId': customer.customerId,
      'catalogId': customer.catalogId,
      'threePL': customer.threePL,
      'state': customer.state,
      'country': customer.country,
      'toteOwner': customer.toteOwner,
      'org': customer.org
    }, options)
      .pipe(map(res => {
        return { 'success': true, 'customerId': customer.customerId };
      }))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }

  deleteCustomer(token: String, customerId: String) {
    const options = this.commonService.createOptionsForHttp(token);
    return this.http.delete(baseURL + 'customers/' + customerId, options)
      .pipe(map(res => this.processHttpmsgService.extractData(res)))
      .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
  }
}
