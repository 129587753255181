import { Injectable } from '@angular/core';
import { RequestOptions, Headers} from '@angular/http';
// import 'rxjs/add/operator/map';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  public createOptionsForHttp(token) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', 'Bearer ' + token);
    // let headers = {headers: new HttpHeaders({'Content-Type':'application/json', 'Authorization': 'Bearer '+ token})}
    const options = new RequestOptions({ headers: headers});
    return options;
  }
}
