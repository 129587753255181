import { Component } from "@angular/core";

import { LoginService } from "./services/login.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  title = "app";

  isLoggedIn: Boolean = false;

  constructor(public loginService: LoginService) {}
  ngOnInit() {
    this.isLoggedIn = this.loginService.tokenExpiryValidationCheck();
  }
  logOut() {
    this.loginService.logout();
  }
}
