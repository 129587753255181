import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup, Form } from '@angular/forms';
import { Material } from '../shared/material';
import { LoginService } from '../services/login.service';
import { MaterialService } from '../services/material.service';

@Component({
  selector: 'app-add-material',
  templateUrl: './add-material.component.html',
  styleUrls: ['./add-material.component.scss']
})
export class AddMaterialComponent implements OnInit {

  material: Material;
  materialForm: FormGroup;
  token: String;

  constructor(public dialogRef: MatDialogRef<AddMaterialComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public materialService: MaterialService) {

      this.materialForm = this.formBuilder.group({
        material: ['', Validators.compose([Validators.required, Validators.pattern('[a-zA-Z0-9-]*')])],
        organization: ['', Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        assetType: ['', Validators.compose([Validators.required, Validators.pattern('[A-Z]*')])],
        description: ['', Validators.compose([Validators.pattern('[a-zA-Z ]*')])],
        shelfLife: [0, Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        inspectionInterval: [0, Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
        warningDays: [0, Validators.compose([Validators.required, Validators.pattern('[0-9]*')])],
      });
    }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      // Do Nothing
      // return true;
    } else {
      // this.commonService.showAlert("Session expired!", "Please login again");
      // return false;
    }
  }

  onSubmit() {
    if (this.materialForm.valid) {
      this.material = this.materialForm.value;
      this.materialService.postMaterial(this.material, this.token)
      .subscribe(res => {
        if (res.success) {
          // console.log( 'customer Added', res.materialId);
          this.materialForm.reset({
            material: '',
            organization: '',
            assetType: '',
            description: '',
            shelfLife: 0,
            inspectionInterval: 0
          });
          this.dialogRef.close();
        }
      });
    }
  }


}
