import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Customer } from '../shared/customer';
import { LoginService } from '../services/login.service';
import { MaterialService } from '../services/material.service';
import { Material } from '../shared/material';
import { ToteService } from '../services/tote.service';
import { Tote } from '../shared/tote';
import { CustomerService } from '../services/customer.service';
import { Return } from '../shared/returnTime';
import { ReturnTimeService } from '../services/return-time.service';
import { HistoryService } from '../services/history.service';


@Component({
  selector: 'app-add-tote',
  templateUrl: './add-tote.component.html',
  styleUrls: ['./add-tote.component.scss']
})
export class AddToteComponent implements OnInit {
  customers: Customer[];
  selectedMaterial: Material[];
  materials: Material[];
  ReturnTimes: Return[];
  selectedReturnTime: Return[];
  tote: Tote;
  token: string;
  location: string;
  user: string;
  toteForm: FormGroup;
  owners: Customer[];
  selectedAssignedTo: Customer[];
  statuses: Status[] = [
    { value: 'New', viewValue: 'New' },
    { value: 'Onsite', viewValue: 'Onsite' },
    { value: 'Ready for Packaging', viewValue: 'RFP' },
    { value: 'Packaged', viewValue: 'Packaged' },
    { value: 'Shipped', viewValue: 'Shipped' },
    { value: 'Expired', viewValue: 'Expired' },
    { value: 'Damaged', viewValue: 'Damaged' },
    { value: 'ReworkInProcess', viewValue: 'Rework' },
    { value: 'Disposed', viewValue: 'Disposed' }
  ];
  history = { serialNumber: '', currentStatus: '', currentLocation: '', transaction: '', changedBy: '', medium : '' };


  constructor(public dialogRef: MatDialogRef<AddToteComponent>,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    public customerService: CustomerService,
    public materialService: MaterialService,
    public toteService: ToteService,
    public returnTimeService: ReturnTimeService,
    public historyService: HistoryService) {
    this.toteForm = this.formBuilder.group({
      serialNumber: ['', Validators.compose([Validators.required, Validators.pattern('[A-Z0-9]*')])],
      manufactureDate: ['', Validators.compose([Validators.required])],
      lastInspectionDate: ['', Validators.compose([Validators.required])],
      currentLocation: ['', Validators.compose([Validators.required, Validators.pattern('[A-Za-z ]*')])],
      product: ['', Validators.compose([Validators.pattern('[0-9]*')])],
      shipDate: [''],
      currentBatch: ['', Validators.compose([Validators.pattern('[A-Z0-9]*')])],
      currentStatus: ['', Validators.required],
      material: ['', Validators.required],
      owner: ['', Validators.required],
      assignedTo: ['', Validators.required],
    });
  }

  ngOnInit() {
    const isLoggedIn = this.loginService.tokenExpiryValidationCheck();
    if (isLoggedIn === true) {
      this.token = localStorage.getItem('token');
      this.location = localStorage.getItem('location');
      this.user = localStorage.getItem('currentUser');

      this.customerService.getCustomers(this.token)
        .subscribe((customers) => {
          this.customers = customers;
          this.owners = customers.filter(customer => customer.toteOwner === true);
        });


      this.materialService.getMaterials(this.token)
        .subscribe((materials) => {
          this.materials = materials;
        });

      this.returnTimeService.getReturnTimes(this.token)
        .subscribe((returns) => {
          this.ReturnTimes = returns;
        });
    }
  }


  onSubmit() {
    if (this.toteForm.valid) {
      this.tote = this.toteForm.value;
      this.toteService.getToteById(this.tote.serialNumber, this.token)
        .subscribe((tote) => {
          if (tote.success) {
            console.log('serial Number exists');
          } else {
            const lastInspection = new Date(this.tote.lastInspectionDate);
            this.tote.lastInspectionDate = lastInspection.toISOString();
            const manufactureDate = new Date(this.tote.manufactureDate);
            this.tote.manufactureDate = manufactureDate.toISOString();
            this.selectedMaterial = this.materials.filter((material) => this.toteForm.get('material').value === material._id);
            const selectMaterial = this.selectedMaterial[0];
            this.tote.nextInspectionDate = this.calcuateNextInspection(this.tote.lastInspectionDate, selectMaterial.inspectionInterval);
            this.tote.expiryDate = this.calculateExpiry(this.tote.manufactureDate, selectMaterial.shelfLife);
            if (this.tote.shipDate) {
              const shipDate = new Date(this.tote.shipDate);
              this.tote.shipDate = shipDate.toISOString();
              this.selectedAssignedTo = this.customers.filter((customer) => this.toteForm.get('assignedTo').value === customer._id);
              const selectAssignedTo = this.selectedAssignedTo[0];
              this.selectedReturnTime = this.ReturnTimes.filter((returnTime) =>
                returnTime.country === selectAssignedTo.country);
              const selectReturnTime = this.selectedReturnTime[0];
              this.tote.estReturnDate = this.calculateReturnDate(this.tote.shipDate, selectReturnTime.returnDays);
            }
            console.log(this.tote);
            this.toteService.postTote(this.tote, this.token)
              .subscribe((res) => {
                if (res.success) {
                  // console.log('tote created');
                  this.history.serialNumber = this.tote.serialNumber;
                  this.history.currentStatus = this.tote.currentStatus;
                  this.history.currentLocation = this.location;
                  this.history.transaction = 'Portal Create';
                  this.history.medium = 'Portal';
                  this.history.changedBy = this.user;
                  // Post History
                  this.historyService.postHistory(this.history, this.token)
                    .subscribe(result => {
                      if (result.success) {
                        console.log('History Added');
                      }
                    });
                  this.dialogRef.close();
                }
              });
          }
        });

    }
  }



  calcuateNextInspection(lastInspection: string, inspectionInterval: number) {
    const inspectionDate = new Date(lastInspection);
    inspectionDate.setDate(inspectionDate.getDate() + inspectionInterval);
    inspectionDate.setMonth(inspectionDate.getMonth() + 1, 1);
    const nextInspectionDate = inspectionDate.toISOString();
    return nextInspectionDate;
  }

  calculateExpiry(manufactureDate: string, shelfLife: number) {
    const expireDate = new Date(manufactureDate);
    expireDate.setDate(expireDate.getDate() + shelfLife);
    expireDate.setMonth(expireDate.getMonth() + 1, 1);
    const expiryDate = expireDate.toISOString();
    return expiryDate;
  }
  calculateReturnDate(shippingDate: string, returnTime: number) {
    const shipDate = new Date(shippingDate);
    shipDate.setDate(shipDate.getDate() + returnTime);
    const estDate = shipDate.toISOString();
    return estDate;
  }
}

export interface Status {
  value: string;
  viewValue: string;
}
