import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { baseURL } from '../shared/baseurl';
import { map, catchError } from 'rxjs/operators';
import { CommonService } from '../services/common.service';
import { ProcessHttpmsgService } from '../services/process-httpmsg.service';
import { History } from '../shared/history';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(public http: Http, public commonService: CommonService,
    public processHttpmsgService: ProcessHttpmsgService) { }

    getHistory(token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.get(baseURL + 'history', options)
        .pipe(map(res => this.processHttpmsgService.extractData(res)))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

    postHistory(history, token: String) {
      const options = this.commonService.createOptionsForHttp(token);
      return this.http.post(baseURL + 'history',
        {
          'serialNumber': history.serialNumber,
          'assignedTo': history.assignedTo,
          'estReturnDate': history.estReturnDate,
          'currentStatus': history.currentStatus,
          'currentLocation': history.currentLocation,
          'lastInspectionDate': history.lastInspectionDate,
          'nextInspectionDate': history.nextInspectionDate,
          'product': history.product,
          'shipDate': history.shipDate,
          'currentBatch': history.currentBatch,
          'changedBy': history.changedBy,
          'transaction': history.transaction,
          'medium': history.medium,
          'changedOn': new Date().toISOString()
        },
        options)
        .pipe(map (res => {
          return { 'success': true };
        }))
        .pipe(catchError(error => this.processHttpmsgService.handleError(error)));
    }

}

